.contacts {
  &__text-wrapper {
    padding-left: 40px;

    .contacts__title {
      color: rgba(255, 255, 255, .75);
      width: 50%;
      margin-left: auto;
      font-weight: 400;
    }
  }

  &__list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 36px;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: flex-start;

    h4 {
      width: 50%;
      font-size: 30px;
      align-items: center;
      flex-shrink: 0;
    }

    p,
    a {
      margin-top: 5px;
    }

    p {
      color: rgba(255, 255, 255, .75);
      font-weight: 400;
    }

    a {
      position: relative;
      text-decoration: none;
      color: rgb(252, 168, 45, .85);

      &:hover {
        color: rgba(239, 196, 73, 1);
      }
    }

  }

  &__item-tel-wrapper ul {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    padding: 0;
    list-style: none;

    li {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .contacts__text-wrapper {
    padding: 0;

    .contacts__title {
      width: 100%;
      margin-left: auto;
    }
  }

  .contacts__list {
    gap: 20px;
  }

  .contacts__item {
    flex-direction: column;
  }

  .contacts__item h4 {
    width: 100%;
    font-size: 24px;
  }
}
