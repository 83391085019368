:root {
  width: 100%;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
  background-color: rgba(1, 15, 41, 1);
  color: rgba(255, 255, 255, 1);
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

h1, h2, h3, h4, p {
  font-family: 'Inter', sans-serif;
  text-align: left;
  margin: 0;
}

a {
  font-family: 'Inter', sans-serif;
  text-align: left;
  text-decoration: none;
}

.section {
  max-width: 1176px;
  margin: 0 auto;
}

.section-secondary {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  padding-bottom: 70px;
}

.container {
  margin: 0 auto 70px;
}

.attention-text {
  color: orangered;
}

.container h2 {
  color: rgba(255, 255, 255, .95);
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 36px;
}

.colored-bg {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;

}

.light-bg {
  padding: 70px 0;
}

html, .root {
  font-size: 18px;
  line-height: 23px;
}

body, .article {
  font-family: sans-serif;
  font-size: 1em;
  line-height: 1.27777778em;
}
h1, .h1 {
  font-size: 4.22222222em;
  line-height: 1.21052632em;
}
h2, .h2 {
  font-size: 2.61111111em;
  line-height: 1.46808511em;
}
h3, .h3 {
  font-size: 1.61111111em;
  line-height: 1.5862069em;
}
h4, .h4 {
  font-size: 1em;
  line-height: 1.27777778em;
}
h5, .h5 {
  font-size: 1em;
  line-height: 1.27777778em;
}

@media (max-width: 1200px) {
  .section {
    max-width: calc(100% - 2*12px);

  }
}

@media (max-width: 1000px) {
  .container {
    margin-bottom: 60px;

    h2 {
      font-size: 25px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    margin-bottom: 40px;

    h2 {
      font-size: 30px;
    }
  }

  .light-bg {
    padding: 30px 0;
  }
}

@media (max-width: 575px) {
  .container {
    width: 100%;

    h2 {
      font-size: 20px;

      h2 {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }
  }

  .light-bg {
    padding: 20px 0;
  }
}
