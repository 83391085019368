.slider {
  mix-blend-mode: normal;
  opacity: .75;
  max-width: calc(100% - 2*40px);
  margin: 0 auto;


  &__container {
    width: 100%;
    height: 15vw;
    overflow: hidden;
    position: relative;
    border: 2px solid #efc449;
    border-radius: 10px;

    .slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: absolute;
      animation: round 16s infinite;
      opacity: 0;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: opacity 0.5s;
      &.active {
        opacity: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }
}

@media (max-width: 2200px) {
  .slider__container {
    height: 20vw;
  }
}

@media (max-width: 1700px) {
  .slider__container {
    height: 25vw;
  }
}

@media (max-width: 1400px) {
  .slider__container {
    height: 27.5vw;
  }
}

@media (max-width: 1200px) {
  .slider__container {
    height: 30vw;
  }
}



@media (max-width: 575px) {
  .slider {
    max-width: calc(100% - 2 * 10px);

    &__container {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 460px) {}
