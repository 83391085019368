.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  &.open {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    position: relative;
    background-color: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    max-width: 472px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .popup__close {
      position: absolute;
      top: -50px;
      right: 0;
      width: 40px;
      height: 40px;
      cursor: pointer;
      background: transparent;
      border: none;

      &:hover {
        &::before,
        &::after {
          background-color: rgba(239, 196, 73, 1);
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: rgb(252, 168, 45, 1);
        transform: translateY(-50%);
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  &__name {
    margin: 0;
    font-size: 14px;
    line-height: 1.25;
    font-weight: 400;
    color: rgb(252, 168, 45, 1);
    align-self: flex-start;
  }

  &__img {
    max-width: 472px;
    max-height: 650px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .popup {
    &__content {
      max-width: calc(100% - 2*10px);
    }

    &__img {
      max-width: 100%;
      max-height: initial;
    }
  }
}
