.header {
  padding-top: 50px;

  &__secondary {
    padding-bottom: 30px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  &__introduction {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      display: block;
      width: 250px;
      height: auto;

      img {
        width: 100%;
      }
    }
  }

  &__logo {
    width: 250px;
    height: auto;
    display: block;
  }

  &__text {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, .75);
  }

  &__redirect {
    width: 200px;
    padding: 15px 25px 14px;
    background: linear-gradient(238deg, rgba(252, 168, 45, 1), rgba(239, 196, 73, 1));
    border-radius: 10px;
    color: rgba(1, 15, 41, 1);
  }


  & .footer__decorative-line {
    margin-bottom: 70px;
  }
}


@media (max-width: 1000px) {
  .header {
    &__introduction {
      width: 100%;
      column-gap: 25px;
    }

    &__text {
      width: auto;
    }

    &__title {
      font-size: 30px;
    }
  }
}

@media (max-width: 768px) {
  .header {
    padding-top: 25px;


    &__introduction {
      column-gap: 20px;
    }

    &__title {
      font-size: 20px;
      margin-bottom: 5px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__nav__item a {
      font-size: 16px;
      width: 100%;
      padding: 12px 5px 13px;
    }
  }
}

@media (max-width: 460px) {
  .header {
    &__title {
      font-size: 15px;
    }

    &__subtitle {
      font-size: 13px;
      line-height: 1;
    }

    &__nav__item a {
      font-size: 13px;
    }

    & .footer__decorative-line {
      margin-bottom: 35px;
    }
  }
}
