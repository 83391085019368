.license-certificate {
  color: rgba(255, 255, 255, .75);

  h1 {
    color: rgba(255, 255, 255, .95);
    font-weight: 700;
    font-size: 35px;
  }

  &__list {
    list-style: none;
    margin: 0 auto;
    padding: 0 40px;
    display: flex;
    align-items: center;
    gap: 35px;
  }

  &__item {
    width: 25%;
    overflow: hidden;
  }

  &__pic {
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}


@media (min-width: 320px) and (max-width: 575px) {
  .license-certificate {
    &__list {
      padding: 0;
      width: 100%;
      flex-wrap: wrap;
    }

    &__item {
      width: 45%;
    }

    h1 {
      font-size: 22px;
    }

    p {
      font-size: .8em;
    }
  }
}
