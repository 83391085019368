.staff {
  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 35px;
    align-items: flex-start;
    border-top: 2px dashed;
    border-color: rgba(239, 196, 73, 1) !important;
    padding: 30px;

    &:nth-child(even) {
      border-left: 2px dashed;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      margin-right: 30px;
      padding-right: 0;
    }

    &:nth-child(odd) {
      border-right: 2px dashed;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      margin-left: 30px;
      padding-left: 0;
      align-items: flex-end;
    }

    &:first-child {
      border-top: 0;
      border-top-right-radius:0;
      border-top-left-radius:0;
      padding-top: 0;
    }

    &:last-child {
      border-bottom-right-radius:0;
      border-bottom-left-radius:0;
      padding-bottom: 0;
    }

    ul {
      width: 100%;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      row-gap: 30px;
    }

    ul li {
      display: flex;
      align-items: flex-start;
      gap: 35px;
    }

    h3 {
      align-self: center;
    }

    p {
      font-size: .9em;
    }
  }

  &__avatar-wrapper {
    display: flex;
    justify-content: center;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 2px solid rgba(239, 196, 73, 1);
    margin-top: 10px;
    overflow: hidden;
    flex-shrink: 0;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  &__description {
    font-size: .8em;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .staff {
    &__list {
      padding: 0;
      width: 100%;
    }

    &__item {
      gap: 20px;

      h3 {
        font-size: 18px;
      }

      ul li {
        flex-direction: column;
      }
    }

    &__avatar-wrapper {
      width: 150px;
      height: 150px;
      align-self: center;
    }

    &_text-wrapper {
      row-gap: 15px;
    }
  }
}
