.accordion {
  width: 100%;
  margin: 2rem auto;
  padding-left: 40px;

  &__item {
    border: 2px solid rgba(239, 196, 73, 1);
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 15%);
  }

  &__item_show .accordion__header::after,
  .accordion__item_slidedown .accordion__header::after {
    transform: rotate(-180deg);
  }


  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    color: rgba(1, 15, 41, 1);
    font-weight: 500;
    font-size: 14px;
    background: linear-gradient(238deg, rgba(252, 168, 45, 1), rgba(239, 196, 73, 1));
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:after {
      flex-shrink: 0;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: auto;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: 1.25rem;
      content: "";
      transition: all 0.2s ease-out;
    }

    &:hover {
      background: rgba(1, 15, 41, 1);
      color: rgba(239, 196, 73, 1);
    }

    &:hover:after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23919673'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

    &.active {
      background: rgba(1, 15, 41, 1);
      color: rgba(239, 196, 73, 1);

      &:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23919673'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
    }
  }

  &__body {
    overflow: hidden;
    transition: all 0.3s ease;

    &.collapse {
      height: 0;
    }

    &.collapsing {
      height: auto;
    }
  }

  &__item_show .accordion__body {
    height: auto;
    transition: all .5s ease;
  }

  &__content {
    padding: 0.75rem 1rem;
    background-color: rgba(255, 255, 255, .15);
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  &__glue-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 2px solid rgba(239, 196, 73, 1);
    border-radius: 10px;
    overflow: hidden;
  }

  &__item:not(.accordion__item_show) .accordion__body {
    height: 0;
    transition: all .5s ease;
  }

  &__glue-item {
    padding: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    font-size: 16px;

    &:nth-child(2n+1) {
      background-color: rgb(1, 15, 42);
    }
  }

  &__description p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .accordion {
    padding: 0;

    &__header {
      font-size: 12px;
    }

    &__glue-item {
      flex-direction: column;
      row-gap: 10px;

      p {
        text-align: center;
      }
    }
  }
}
