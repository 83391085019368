.information {
  mix-blend-mode: normal;
  backdrop-filter: blur(40px);

  h2 {
    color: rgba(255, 255, 255, .95);
    font-weight: 700;
  }

  p {
    color: rgba(255, 255, 255, .75);
    font-weight: 400;
  }

  &__list {
    display: flex;
    list-style: none;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    margin-top: 36px;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;

    a {
      position: relative;
      color: rgb(252, 168, 45, .85);
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: rgba(239, 196, 73, 1);
      }
    }
  }
}

@media (max-width: 768px) {
  .information__list {
    padding: 0;
  }
}

@media (max-width: 460px) {
  .information__list {
    margin-top: 20px;
  }

}
