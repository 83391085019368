.flip {
  position: relative;

  & > .front,
  & > .back {
    display: block;
    transition-timing-function: ease;
    transition-duration: .4s;
    transition-property: transform, opacity;
  }

  & > .front {
    transform: rotate(0deg);
  }

  & > .back {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(46, 59, 78);;
    transform: rotateX(-180deg);
  }

  &:hover > .front {
    transform: rotateX(180deg);
  }

  &:hover > .back {
    opacity: 1;
    transform: rotateX(0deg);
  }

  & .flip-vertical > .back {
    transform: rotateX(-180deg);
  }

  & .flip-vertical:hover > .front {
    transform: rotateX(180deg);
  }
}

.partners {
  &__list {
    padding: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 150px;
    background-color: #fff;
    overflow: hidden;
    border-radius: 15px;
    border: 2px solid rgba(239, 196, 73, 1);
    perspective: 1000px;

    img {
      width: 200px;
      height: 100px;
      object-fit: contain;
    }

    h6 {
      text-align: center;
      padding: 0 5px;
    }

    .back {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

@media (max-width: 768px) {
  .partners__item {
    width: 45%;
  }
}

@media (max-width: 575px) {
  .partners__list {
    gap: 15px;
  }

  .partners__item {
    width: 45%;
    max-height: 120px;

    img {
      height: 100px;
      object-fit: contain;
      width: 100%;
    }
  }
}
