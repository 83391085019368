.products {
  h2 {
    color: rgba(255, 255, 255, .95);
    font-weight: 700;
  }

  h3 {
    font-weight: 700;
    line-height: 100%;
  }


  &__terms-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-bottom: 0;
  }

  &__terms-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    h4 {
      width: 25%;
      align-items: center;
      font-size: 30px;
    }

    p {
      margin-top: 10px;
      width: 75%;
      color: rgba(255, 255, 255, .75);
    }

    ul {
      margin: 5px 0 0;
      padding-left: 20px;
      width: 75%;
    }

    ul li p {
      width: 100%;
    }

    a {
      text-decoration: none;
      color: rgb(252, 168, 45, .85);

      &:hover {
        color: rgba(239, 196, 73, 1);
      }
    }
  }

  &__wrapper {
    margin-bottom: 70px;
  }
}


@media (max-width: 768px) {
  .products {
    &__terms-list {
      padding: 0;
    }

    &__terms-item {
      flex-direction: column;

      h4 {
        font-size: 23px;
      }

      p {
        width: 100%;
      }

      ul {
        width: 100%;
      }
    }
  }
}

@media (max-width: 575px) {
  .products {
    h3 {
      font-size: 15px;
    }

    &__accordion {
      width: 100%;
    }
  }
}
