.yandex-map {
  max-width: 1176px;
  margin: 0 auto;
  border: 2px solid rgba(239, 196, 73, 1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

@media (max-width: 1200px) {
  .yandex-map {
    max-width: calc(100% - 2*10px);
  }
}

@media (max-width: 768px) {
  .yandex-map {
    max-width: 100%;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}

@media (max-width: 575px) {
  .yandex-map {
    max-width: initial;
    width: 100%;
  }

}
