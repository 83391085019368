.history {
  margin: 0 auto;

  &__title {
    font-size: 35px;
  }

  p {
    color: rgba(255, 255, 255, .75);
  }

  &__list {
    width: 100%;
    margin: auto;
    list-style: none;
    padding: 0;
  }

  &__paragraph {
    border-top: 2px dashed;
    border-color: rgba(239, 196, 73, 1) !important;
    margin:0;
    padding: 30px;

    &:nth-child(even) {
      border-left: 2px dashed;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      margin-right: 30px;
      padding-right: 0;
    }

    &:nth-child(odd) {
      border-right: 2px dashed;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      margin-left: 30px;
      padding-left: 0;
    }

    &:first-child {
      border-top: 0;
      border-top-right-radius:0;
      border-top-left-radius:0;
      padding-top: 0;
    }

    &:last-child {
      border-bottom-right-radius:0;
      border-bottom-left-radius:0;
      padding-bottom: 0;
    }
  }

}

@media (min-width: 320px) and (max-width: 575px) {
  .history {
    .history__title {
      font-size: 22px;
    }

    &__list {
      padding: 0;
      width: 100%;
    }

    p {
      font-size: .8em;
    }
  }

  .section-secondary {
    padding-bottom: 35px;
  }
}
