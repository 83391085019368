.footer {

  &__decorative-line {
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(239, 196, 73, 1);
  }

  &__container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 10px;
  }

  &__breadcrumbs-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 0;
    padding: 0;
  }

  &__breadcrumbs-item {
    a {
      text-decoration: none;
      color: rgba(255, 255, 255, .75);

      &:hover {
        color: rgba(239, 196, 73, 1);
      }
    }
  }

  &__copyright {
    color: rgba(255, 255, 255, .75);
    text-align: right;
  }
}

@media (max-width: 768px) {
  .footer {
    &__decorative-line {
      margin-bottom: 15px;
    }

    &__container {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 25px;
    }

    &__copyright {
      text-align: left;
    }
  }
}
