.nav {
  &__list {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    text-align: center;
    gap: 5px;
  }

  &__item {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(238deg, rgba(252, 168, 45, 1), rgba(239, 196, 73, 1));

    border-radius: 10px;

    a {
      width: 100%;
      text-align: center;
      position: relative;
      color: rgba(1, 15, 41, 1);
      display: block;
      padding: 13px 0 15px;
      transition: all 0.3s ease-in-out;
      border: 1px solid rgba(1, 15, 41, 1);
      border-radius: 10px;

      &:hover {
        background-color: rgba(1, 15, 41, 1);
        color: rgba(239, 196, 73, 1);
        border: 1px solid rgba(239, 196, 73, 1);
      }

      &:active {
        color: #fff;
        opacity: .75;
      }

      &:active:after {
        background-color: #fff;
        opacity: .75;
      }
    }
  }
}

@media (max-width: 768px) {
  .nav {
    &__item {

      a {
        font-size: 16px;
        width: 100%;
        padding: 12px 5px 13px;
      }

    }
  }
}

@media (max-width: 592px) {
  .nav {
    &__list {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      width: 45%;

      a {
        font-size: 18px;
      }
    }
  }
}
