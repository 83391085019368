#root {

  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

h1, h2, h3, h4, p {
  font-family: 'Inter', sans-serif;
  text-align: left;
  margin: 0;
}

a {
  font-family: 'Inter', sans-serif;
  text-align: left;
  text-decoration: none;
}

.section {
  max-width: 1176px;
  margin: 0 auto;
}

.section-secondary {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  padding-bottom: 70px;
}

.container {
  margin: 0 auto;
}

.attention-text {
  color: orangered;
}

.container h2 {
  color: rgba(255, 255, 255, .95);
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 36px;
}

.colored-bg {
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;

}

.light-bg {
  padding: 70px 0;
}

html, .root {
  font-size: 18px;
  line-height: 23px;
}

body, .article {
  font-family: sans-serif;
  font-size: 1em;
  line-height: 1.27777778em;
}
h1, .h1 {
  font-size: 4.22222222em;
  line-height: 1.21052632em;
}
h2, .h2 {
  font-size: 2.61111111em;
  line-height: 1.46808511em;
}
h3, .h3 {
  font-size: 1.61111111em;
  line-height: 1.5862069em;
}
h4, .h4 {
  font-size: 1em;
  line-height: 1.27777778em;
}
h5, .h5 {
  font-size: 1em;
  line-height: 1.27777778em;
}

@media (max-width: 1200px) {
  .section {
    max-width: calc(100% - 2*12px);

  }
}

@media (max-width: 1000px) {
  .container h2 {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .container h2 {
    font-size: 30px;
  }

  .light-bg {
    padding: 30px 0;
  }
}

@media (max-width: 575px) {
  .container h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .light-bg {
    padding: 20px 0;
  }
}
